// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-post-template-js": () => import("./../../../src/components/blog-post-template.js" /* webpackChunkName: "component---src-components-blog-post-template-js" */),
  "component---src-components-candidate-template-index-js": () => import("./../../../src/components/candidate-template/index.js" /* webpackChunkName: "component---src-components-candidate-template-index-js" */),
  "component---src-components-election-template-js": () => import("./../../../src/components/election-template.js" /* webpackChunkName: "component---src-components-election-template-js" */),
  "component---src-components-topics-template-js": () => import("./../../../src/components/topics-template.js" /* webpackChunkName: "component---src-components-topics-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-edit-js": () => import("./../../../src/pages/_/edit.js" /* webpackChunkName: "component---src-pages-edit-js" */),
  "component---src-pages-elections-js": () => import("./../../../src/pages/elections.js" /* webpackChunkName: "component---src-pages-elections-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

